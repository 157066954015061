import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-settings" }
const _hoisted_2 = { class: "center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_it_button = _resolveComponent("it-button")!
  const _component_it_button_group = _resolveComponent("it-button-group")!
  const _component_it_collapse_item = _resolveComponent("it-collapse-item")!
  const _component_it_collapse = _resolveComponent("it-collapse")!
  const _component_it_divider = _resolveComponent("it-divider")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_it_collapse, null, {
      default: _withCtx(() => [
        _createVNode(_component_it_collapse_item, {
          opened: _ctx.collapsable,
          title: _ctx.$t('title')
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_it_button_group, { class: "mb-2" }, {
                default: _withCtx(() => [
                  _createVNode(_component_it_button, {
                    outlined: "",
                    disabled: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("#1")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_it_button, {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loadPage('CreateRepo'))),
                    icon: "folder",
                    type: "black",
                    outlined: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('button.devops')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_it_button_group, { class: "mb-2" }, {
                default: _withCtx(() => [
                  _createVNode(_component_it_button, {
                    outlined: "",
                    disabled: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("#2")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_it_button, {
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.loadPage('CreateProject'))),
                    icon: "code",
                    type: "black",
                    outlined: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('button.vue')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_it_button_group, { class: "mb-2" }, {
                default: _withCtx(() => [
                  _createVNode(_component_it_button, {
                    outlined: "",
                    disabled: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("#3")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_it_button, {
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.loadPage('HostOnAzure'))),
                    icon: "cloud",
                    type: "black",
                    outlined: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('button.azure')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_it_button_group, { class: "mb-2" }, {
                default: _withCtx(() => [
                  _createVNode(_component_it_button, {
                    outlined: "",
                    disabled: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("#4")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_it_button, {
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.loadPage('AutoRelease'))),
                    icon: "autorenew",
                    type: "black",
                    outlined: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('button.pipeline')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_it_button_group, { class: "mb-2" }, {
                default: _withCtx(() => [
                  _createVNode(_component_it_button, {
                    outlined: "",
                    disabled: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("#5")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_it_button, {
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.loadPage('SyncWithGithub'))),
                    icon: "published_with_changes",
                    type: "black",
                    outlined: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('button.github')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_it_button_group, { class: "mb-2" }, {
                default: _withCtx(() => [
                  _createVNode(_component_it_button, {
                    outlined: "",
                    disabled: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("#6")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_it_button, {
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.loadPage('CustomDomain'))),
                    icon: "dns",
                    type: "black",
                    outlined: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('button.domain')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }, 8, ["opened", "title"])
      ]),
      _: 1
    }),
    _createVNode(_component_it_divider),
    _createVNode(_component_router_view)
  ]))
}