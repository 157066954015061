
import { Options, Vue } from 'vue-class-component'
import Dictionary from '@localizations/buildAWebsite'

@Options({
  i18n: {
    messages: Dictionary
  }
})
export default class BuildAWebsite extends Vue {
  collapsable = true;

  loadPage (path: string): void {
    this.$router.push({ name: path })
  }
}
