export default {
  en: {
    title: 'How this website was made:',
    button: {
      devops: 'Create a Devops Repository',
      vue: 'Create a Vue/TypeScript project',
      azure: 'Host on Azure',
      pipeline: 'Auto-release pipeline on PR',
      github: 'Sync with Github',
      domain: 'Add a custom domain name'
    }
  }
}
